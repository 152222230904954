import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export default {
  convertToMMSS(timeString) {
    if (!timeString) return;
    const [hours, minutes, seconds] = timeString?.split(':');
    const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);

    return `${totalMinutes}:${seconds}`;
  },

  formatSecondsToHhMmSs(seconds) {
    const durationObj = dayjs.duration(seconds, 'seconds');
    return durationObj.format('HH:mm:ss');
  },

  formatMmSsToSeconds(timeString) {
    if (!timeString) return;
    const [minutes, seconds] = timeString.split(':');

    return parseInt(minutes) * 60 + parseInt(seconds);
  },
};

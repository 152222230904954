import dayjs from 'dayjs';
import cycleTypes from 'src/config/cycle-type-config';
import { dateTimeFormat } from 'src/config/date-format-config.js';
import { useToast } from 'vue-toastification';

const convertDateToISOFormat = (date) => {
  const hoursToAdd =
    new Date().toString().split('GMT').pop().split(' ')[0] / 100;
  return dayjs(
    new Date(date.replace(/-/g, '/')).toISOString(),
    dateTimeFormat
  ).add(hoursToAdd, 'hour');
};

const getDateRange = (startDate, endDate) => {
  return [convertDateToISOFormat(startDate), convertDateToISOFormat(endDate)];
};

export default async function populateFilters() {
  let {
    taskId,
    deviceId,
    startTime,
    endTime,
    cycle_type,
    toast,
    tags,
    min_cycle_time_in_bin,
    max_cycle_time_in_bin,
    cycles,
    start_time,
    end_time,
    missed_steps,
    vt,
    c_start_date,
    c_end_date,
    c_devices,
    stepIndex,
    timeDuration, // query comes from Metabase
  } = this.$route.query;
  if (taskId && deviceId) {
    if (toast) {
      useToast().info('If Video(s) not available, Please refresh in a while!', {
        timeout: 5000,
      });
    }

    this.setSelectedTask(Number(taskId));
    await this.fetchTaskDetails(Number(taskId));

    this.selectedDevices = JSON.parse(deviceId).map(
      (d) => this.deviceSerialNumToDisplayNameMap[d]
    );

    if (tags) {
      this.selectedTags = JSON.parse(tags);
    }

    this.setSelectedVirtualTask(Number(vt));

    if (!(startTime && endTime)) {
      startTime = timeDuration.split('~')[0];
      endTime = timeDuration.split('~')[1];
    }

    this.dateRange = getDateRange(startTime, endTime);

    const isComparing = c_start_date && c_end_date && c_devices.length;
    if (isComparing) {
      this.setShowCompareAnalytics(true);
      this.setDateRangeForComparison(getDateRange(c_start_date, c_end_date));
    }
    if (c_devices)
      this.selectedDevicesForComparison = JSON.parse(c_devices).map(
        (d) => this.deviceSerialNumToDisplayNameMap[d]
      );

    this.setSelectedCycleType(cycle_type || cycleTypes['All Cycles']);

    this.minCycleTime = min_cycle_time_in_bin;
    this.maxCycleTime = max_cycle_time_in_bin;
    this.durationStartTime = start_time;
    this.durationEndTime = end_time;
    if (cycles) this.cyclesFromParams = JSON.parse(cycles);
    if (missed_steps)
      this.selectedSteps = JSON.parse(missed_steps).filter(
        (index) => !this.isNegativeOrOptionalStep(index)
      );
    this.getData();
    if (isComparing) this.getDataForComparison();
  }
}

var dayjs = require('dayjs');
var duration = require('dayjs/plugin/duration');
var utc = require('dayjs/plugin/utc');

dayjs.extend(duration);
dayjs.extend(utc);

export function getDurationMinutes(dur) {
  const [hour, minute, second] = dur.split(':').map((t) => parseInt(t));
  return dayjs.duration({ hour, minute, second }).asMinutes();
}

export default function(timeInMs) {
  if (timeInMs < 0) return '00:00:00';
  // console.log('time in ms', timeInMs);
  const dur = dayjs.duration(Number(timeInMs));

  return dayjs.utc(dur.asMilliseconds()).format('HH:mm:ss'); // "00:01:00"
}

export function getIntervalTime(timeInMs) {
  if (timeInMs < 0) return '00';
  const dur = dayjs.duration(Number(timeInMs));

  const time = dayjs.utc(dur.asMilliseconds()).format('mm:ss');
  return time;
}
